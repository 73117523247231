//utilities import
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import APIURL from "../APIURL";
import useSWR from "swr";
import axios from "axios";
import { Prompt } from "react-router-dom";
import moment from "moment";

//kendo elements
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { FieldArray } from "@progress/kendo-react-form";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { SvgIcon } from "@progress/kendo-react-common";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Fade } from "@progress/kendo-react-animation";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { pencilIcon, trashIcon, downloadIcon } from "@progress/kendo-svg-icons";
import {
  Grid as GridTelerik,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Card, CardHeader } from "@progress/kendo-react-layout";

//localization
import { useLocalization } from "@progress/kendo-react-intl";
import {
  FormNumericTextBox,
  FormInput,
  FormDatePicker,
  RequiredFormTextArea,
  FormDropDownList,
  FormCheckbox,
} from "../common/Kendo/form-components";

import {
  requiredValidator,
  requiredMax50Validator,
  requiredMax255Validator,
} from "../common/Kendo/validators";

//component
import ApproveStudySubject from "../studysubject/ApproveStudySubject";
import TrialMap from "../studysubject/TrialMap";
import TabChange from "../common/commoncomponents/TabChange";
import AddFile from "../common/commoncomponents/AddFile";
import PermissionDenied from "../common/commoncomponents/PermissionDenied";
import DeleteObject from "../common/commoncomponents/DeleteObject";
import AddNote from "../common/commoncomponents/AddNote";
import Frequencies from "../common/commoncomponents/Frequencies";
import Units from "../common/commoncomponents/Units";
import GlobalNotificationTimer from "../common/commoncomponents/GlobalNotificationTimer";

const languageContext = React.createContext({
  intl: null,
});

const MyContext = React.createContext({
  FormRef: {},
});

const arrayLengthValidator = (value) =>
  value && value.length ? "" : "Please add at least one record.";

const onRemoveContext = React.createContext((options) => null);
// Create context for your state
const stateContext = React.createContext([{}, () => {}]);

export const FormUnitDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownList
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        textField={"name"}
        dataItemKey={"value"}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace("unit", "other_unit");

          currentContext.FormRef.current.valueSetter(fieldValue, "");
        }}
      />

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormFrequencyDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const currentContext = React.useContext(MyContext);
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownList
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        textField={"name"}
        dataItemKey={"value"}
        id={id}
        disabled={disabled}
        {...others}
        onChange={(e) => {
          fieldRenderProps.onChange(e);
          let fieldValue = fieldRenderProps.name.replace(
            "frequency",
            "other_frequency"
          );

          currentContext.FormRef.current.valueSetter(fieldValue, "");
        }}
      />

      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

const CustomCell = (props) => {
  const currentContext = React.useContext(MyContext);

  if (props.field === "medication") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          maxLength={255}
        />
      </td>
    );
  }
  if (props.field === "reason") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          maxLength={255}
        />
      </td>
    );
  }
  if (props.field === "other_unit") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={
            props.dataItem.unit.value === "Other" ? requiredMax50Validator : ""
          }
          maxLength={255}
          disabled={props.dataItem.unit.value !== "Other" ? true : false}
        />
      </td>
    );
  }
  if (props.field === "other_frequency") {
    return (
      <td>
        <Field
          component={FormInput}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={
            props.dataItem.frequency.value === "Other"
              ? requiredMax255Validator
              : ""
          }
          maxLength={255}
          disabled={props.dataItem.frequency.value !== "Other" ? true : false}
        />
      </td>
    );
  }
  if (props.field === "continuous_atfinal") {
    return (
      <td>
        <Field
          component={FormCheckbox}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          label={languageContext.intl.toLanguageString(
            "custom.continuousatfinal"
          )}
        />
      </td>
    );
  }
  if (props.field === "single_dose") {
    return (
      <td>
        <Field
          component={FormNumericTextBox}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          // format={"n0"}
        />
      </td>
    );
  } else if (props.field === "start_date") {
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
        />
      </td>
    );
  } else if (props.field === "end_date") {
    console.log(
      "props.dataItem.continuous_atfinal",
      props.dataItem.continuous_atfinal
    );
    return (
      <td>
        <Field
          component={FormDatePicker}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={props.dataItem.continuous_atfinal ? "" : requiredValidator}
          disabled={props.dataItem.continuous_atfinal ? true : false}
        />
      </td>
    );
  } else if (props.field === "unit") {
    return (
      <td>
        <Field
          component={FormUnitDropDownList}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          data={Units}
        />
      </td>
    );
  } else if (props.field === "frequency") {
    return (
      <td>
        <Field
          component={FormFrequencyDropDownList}
          name={`concomitant_medications[${props.dataIndex}].${props.field}`}
          validator={requiredValidator}
          data={Frequencies}
        />
      </td>
    );
  }
};

const CommandCell = (cellProps) => {
  const onRemove = React.useContext(onRemoveContext);
  // Consume the state context, which will give you both the state and the setter.
  const [state, setState] = React.useContext(stateContext);
  const currentContext = React.useContext(MyContext);

  const onClick = (e) => {
    e.preventDefault();
    if (cellProps.dataItem.status && cellProps.dataItem.status === "new") {
      onRemove({ index: cellProps.dataIndex });
    } else {
      currentContext.deletearray.push(cellProps.dataItem.id);

      onRemove({ index: cellProps.dataIndex });
    }
  };

  return (
    <td>
      {cellProps.dataItem.id ? (
        ""
      ) : (
        <SvgIcon
          icon={trashIcon}
          style={{ color: "red", cursor: "pointer" }}
          onClick={onClick}
        />
      )}
    </td>
  );
};

const FormGrid = (fieldArrayRenderProps) => {
  const { validationMessage, visited } = fieldArrayRenderProps;
  const currentContext = React.useContext(MyContext);
  const [state, setState] = React.useState({});
  const onAdd = React.useCallback(
    (e) => {
      currentContext.savingmode = false;
      e.preventDefault();

      fieldArrayRenderProps.onUnshift({
        value: {
          status: "new",
          medication: "",
          reason: "",
          single_dose: null,
          unit: "",
          other_unit: "",
          frequency: "",
          other_frequency: "",
          start_date: null,
          end_date: null,
          continuous_atfinal: false,
        },
      });
    },
    [fieldArrayRenderProps.onUnshift]
  );

  return (
    <div>
      {visited && validationMessage && <Error>{validationMessage}</Error>}

      <stateContext.Provider value={[state, setState]}>
        <onRemoveContext.Provider value={fieldArrayRenderProps.onRemove}>
          <GridTelerik
            style={{
              maxHeight: "500px",
            }}
            data={fieldArrayRenderProps.value}
          >
            <GridToolbar>
              <div className="float-right">
                <Button
                  primary={true}
                  type={"button"}
                  onClick={(e) => {
                    currentContext.savingmode = false;
                    onAdd(e);
                  }}
                >
                  {languageContext.intl.toLanguageString("custom.new")}
                </Button>
              </div>
            </GridToolbar>
            <Column
              field="code"
              title={
                <>
                  <span>
                    {languageContext.intl.toLanguageString("custom.id")}
                  </span>
                </>
              }
            />

            <Column
              field="medication"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.medication"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.medication")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column
              field="reason"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.reason"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.reason")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="single_dose"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.singledose"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.singledose")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="unit"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString("custom.unit")}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.unit")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="other_unit"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.otherunit"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.otherunit")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="frequency"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.frequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.frequency")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="other_frequency"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.otherfrequency"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString(
                      "custom.otherfrequency"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="start_date"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.startdate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.startdate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="end_date"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.enddate"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString("custom.enddate")}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />
            <Column
              field="continuous_atfinal"
              title={
                <>
                  <span
                    title={languageContext.intl.toLanguageString(
                      "custom.continuousatfinal"
                    )}
                    className="text-responsive-overflow"
                  >
                    {languageContext.intl.toLanguageString(
                      "custom.continuousatfinal"
                    )}
                  </span>
                  <span style={{ color: "red" }}>{"*"}</span>
                </>
              }
              cell={CustomCell}
            />

            <Column cell={CommandCell} filterable={false} width="50px" />
          </GridTelerik>
        </onRemoveContext.Provider>
      </stateContext.Provider>
    </div>
  );
};

export function ListConcomitantMedication(props) {
  //redux
  const UserDetail = useSelector((state) => state.UserDetail);
  const EditSubject = useSelector((state) => state.EditSubject);

  //localization
  const localizationService = useLocalization();
  languageContext.intl = useLocalization();

  //notes data
  const fetcher2 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);
  const { data: notes, Noteserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listnote/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  const { data: checkapprove, checkapproveerror } = useSWR(
    props.location.state
      ? [
          APIURL + `/common/checkapprove/`,
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher2
  );

  //notes data
  const fetcher4 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
          section: props.location.state.section.id,
        },
      })
      .then((res) => res.data);

  const { data: attachements, Attchementserror } = useSWR(
    props.location.state
      ? [
          APIURL + "/common/listfile/",
          props.location.state.data.id,
          props.location.state.section.id,
        ]
      : null,
    fetcher4
  );

  //variables
  const [approved, setApproved] = useState(false);
  const [approvedformchanged, setapprovedformchanged] = useState(false);
  const MyForm = React.createRef(); //handle dropdowns change and form modification
  const [loading, setLoading] = useState(false);
  const [EditNote, setEditNote] = useState([]); //to transfer data  edit note
  const [ModifiedData, setModifiedData] = useState();
  const [AllData, setAllData] = useState([]);

  //Modification Reason Dialog
  const [VModifyreason, setVModifyreason] = useState(false);

  const toggleDModifyreason = () => {
    setVModifyreason(!VModifyreason);
  };

  //dialog for adding and updating files :
  const [VaddFile, setVaddFile] = useState(false);

  const toggleDaddFile = () => {
    setVaddFile(!VaddFile);
  };

  //data
  const fetcher1 = (url) =>
    axios
      .get(url, {
        params: {
          subject: props.location.state.data.id,
        },
      })
      .then((res) => res.data);
  const { data, error } = useSWR(
    props.location.state
      ? [
          APIURL + "/opera/baseline/addconcomitantmedication/",
          props.location.state.data.id,
        ]
      : null,
    fetcher1
  );

  //FILE RELATED ACTIONS
  const Fileactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "grey" }}
              icon={downloadIcon}
              onClick={() => {
                EditSubject
                  ? DownloadFile(dataItem.dataItem.id, dataItem.dataItem.title)
                  : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              svgStyle={{ cursor: "pointer", color: "red" }}
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatefile/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.title);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  const DownloadFile = (id, title) => {
    axios
      .get(APIURL + `/common/downloadfile/${id}/`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", title);
        document.body.appendChild(link);
        link.click();
      });
  };

  //tooltip
  const TooltipContentTemplate = (props) => {
    return (
      <div className="custom-tooltip">
        <strong>{props.title}</strong>
      </div>
    );
  };

  const ChangeApproveSection = (value) => {
    setapprovedformchanged(value);
  };

  //date template
  const DateCreatedTemplate = (dataItem) => {
    return (
      <td>
        {moment(dataItem.dataItem.date_created).format("YYYY-MM-DD HH:mm")}
      </td>
    );
  };

  // Notification
  const [ErrorNotification, setErrorNotification] = useState("");
  const position = {
    topCenter: {
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const [state, setState] = React.useState({
    success: false,
    nosave: false,
    deleted: false,
    nomodify: false,
  });

  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });

  const { success, nosave, deleted, nomodify } = state;

  //Permission dialog
  const [VPermission, setVPermission] = useState(false);

  const toggleDPermission = () => {
    setVPermission(!VPermission);
  };

  //Dialog for tabstrip change
  const [selectedTab, setSelectedTab] = useState(null);
  const [VTabStripChange, setVTabStripChange] = useState(false);

  const toggleDTabStripChange = () => {
    setVTabStripChange(!VTabStripChange);
  };

  //callback function to send state from child to parent
  const TabCallBack = (value) => {
    setSelected(value);
    setapprovedformchanged(false);
  };

  //dialog for deleting object
  const [DeleteAPI, setDeleteAPI] = useState("");
  const [Name, setName] = useState("");
  const [Vdelete, setVdelete] = useState(false);

  const toggleDdelete = () => {
    setVdelete(!Vdelete);
  };

  //dialog for adding and updating and deleting notes
  const [VaddNote, setVaddNote] = useState(false);

  const toggleDaddNote = () => {
    setVaddNote(!VaddNote);
  };
  const [VeditNote, setVeditNote] = useState(false);

  const toggleDeditNote = () => {
    setVeditNote(!VeditNote);
  };

  //NoteNameTemplate
  const NoteNameTemplate = (dataItem) => {
    return dataItem.dataItem.note.length > 20 ? (
      <td style={{ cursor: "pointer" }}>
        <Tooltip
          openDelay={0}
          tooltipStyle={{
            width: "200px",
            textAlign: "center",
          }}
          content={(props) => <TooltipContentTemplate title={props.title} />}
          anchorElement="target"
          position="top"
          parentTitle={true}
        >
          <div title={dataItem.dataItem.note}>
            {dataItem.dataItem.note.slice(0, 20)}
            {"..."}
          </div>
        </Tooltip>
      </td>
    ) : (
      <td>
        {" "}
        <div>{dataItem.dataItem.note}</div>
      </td>
    );
  };

  //NOTE ACTIONS
  const Noteactions = (dataItem) => {
    return (
      <td>
        <div className="row">
          <div className="col-6">
            <SvgIcon
              className="EditActionButtons"
              icon={pencilIcon}
              onClick={() => {
                setEditNote(dataItem.dataItem);
                EditSubject ? toggleDeditNote() : toggleDPermission();
              }}
            />
          </div>
          <div className="col-6">
            <SvgIcon
              className="DeleteActionButtons"
              icon={trashIcon}
              onClick={() => {
                setDeleteAPI(`/common/updatenote/${dataItem.dataItem.id}/`);
                setName(dataItem.dataItem.note);
                EditSubject ? toggleDdelete() : toggleDPermission();
              }}
            />
          </div>
        </div>
      </td>
    );
  };

  useEffect(() => {
    let array = [];
    if (data) {
      data.map((d) => {
        array.push({
          id: d.id,
          created_by: d.created_by,
          modified_by: d.modified_by,
          createdBy: d.createdBy,
          modifiedBy: d.modifiedBy,
          section: d.section,
          study_subject: d.study_subject,
          medication: d.medication,
          reason: d.reason,
          single_dose: d.single_dose,
          unit: d.unit ? { name: d.unit, value: d.unit } : "",
          other_unit: d.other_unit,
          frequency: d.frequency
            ? { name: d.frequency, value: d.frequency }
            : "",
          other_frequency: d.other_frequency,
          start_date: d.start_date !== null ? new Date(d.start_date) : null,
          end_date: d.end_date !== null ? new Date(d.end_date) : null,
          continuous_atfinal: d.continuous_atfinal,
          code: d.code,
        });
      });
      setAllData(array);
    }
    if (checkapprove) {
      setApproved(checkapprove);
    }
  }, [data, checkapprove]);

  //goback function

  const goBack = (event) => {
    if (event.type === "click") {
      props.history.push("/crf/studysubject/list");
    }
  };

  const CreateConcomMedications = (dataItem) => {
    if (dataItem.isValid) {
      if (Object.keys(AllData).length !== 0) {
        if (dataItem.isModified) {
          setModifiedData(dataItem.values);
          toggleDModifyreason();
        } else {
          onToggle("nomodify");
          setLoading(false);
          setTimeout(() => {
            setState({ ...state, nomodify: false });
          }, GlobalNotificationTimer);
        }
      } else {
        let array = [];
        if (dataItem.values.concomitant_medications) {
          dataItem.values.concomitant_medications.map((d) => {
            array.push({
              created_by: UserDetail.id,
              modified_by: null,
              study_subject: props.location.state.data.id,
              section: props.location.state.section.id,
              status: d.status ? d.status : "edit",
              medication: d.medication,
              reason: d.reason,
              single_dose: d.single_dose,
              unit: d.unit.value,
              other_unit: d.other_unit,
              frequency: d.frequency.value,
              other_frequency: d.other_frequency,
              start_date: d.start_date
                ? moment(d.start_date).format("YYYY-MM-DD")
                : null,
              end_date: d.end_date
                ? moment(d.end_date).format("YYYY-MM-DD")
                : null,
              continuous_atfinal: d.continuous_atfinal,
            });
          });
        }
        setLoading(true);
        axios
          .post(APIURL + "/opera/baseline/addconcomitantmedication/", {
            modified_by: UserDetail.id,
            section: props.location.state.section.id,
            study_subject: props.location.state.data.id,
            concomitant_medications: array,
          })
          .then((res) => {
            onToggle("success");
            setTimeout(() => {
              setState({ ...state, success: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          })
          .catch((error) => {
            setErrorNotification(error.response.status);
            onToggle("nosave");
            setTimeout(() => {
              setState({ ...state, nosave: false });
              setLoading(false);
            }, GlobalNotificationTimer);
          });
      }
    }
  };

  //TAB STRIP
  const [selected, setSelected] = React.useState(0);

  const handleSelect = (e) => {
    if (selected === 1) {
      if (approvedformchanged === true) {
        setSelectedTab(e.selected);
        toggleDTabStripChange();
      } else {
        setSelected(e.selected);
      }
    } else if (
      MyForm.current &&
      Object.keys(MyForm.current.modified).length !== 0
    ) {
      setSelectedTab(e.selected);
      toggleDTabStripChange();
    } else {
      setSelected(e.selected);
    }
  };
  const [Subselected, setSubSelected] = React.useState(0);

  const handleSubSelect = (e) => {
    setSubSelected(e.selected);
  };

  //prompt function to trigger any change
  const PromptFunction = (formRenderProps) => {
    let boolean = false;
    if (formRenderProps.modified) {
      boolean = true;
    } else {
      boolean = false;
    }

    return boolean;
  };

  const CreateModification = (dataItem) => {
    toggleDModifyreason(false);
    let array = [];
    if (ModifiedData.concomitant_medications) {
      ModifiedData.concomitant_medications.map((d) => {
        array.push({
          id: d.id,
          created_by: d.status === "new" ? UserDetail.id : d.created_by,
          modified_by: d.status === "new" ? null : UserDetail.id,
          study_subject: props.location.state.data.id,
          section: props.location.state.section.id,
          status: d.status ? d.status : "edit",
          medication: d.medication,
          reason: d.reason,
          single_dose: d.single_dose,
          unit: d.unit.value,
          other_unit: d.other_unit,
          frequency: d.frequency.value,
          other_frequency: d.other_frequency,
          start_date: moment(d.start_date).format("YYYY-MM-DD"),
          end_date:  d.end_date ? moment(d.end_date).format("YYYY-MM-DD") : null,
          continuous_atfinal: d.continuous_atfinal,
        });
      });
    }
    setLoading(true);
    axios
      .put(APIURL + "/opera/baseline/updateconcomitantmedication/", {
        modified_by: UserDetail.id,
        study_subject: props.location.state.data.id,
        section: props.location.state.section.id,
        concomitant_medications: array,
        modification_reason: dataItem.modification_reason,
      })
      .then((res) => {
        onToggle("success");
        setTimeout(() => {
          setState({ ...state, success: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      })
      .catch((error) => {
        setErrorNotification(error.response.status);
        onToggle("nosave");
        setTimeout(() => {
          setState({ ...state, nosave: false });
          setLoading(false);
        }, GlobalNotificationTimer);
      });
  };

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">
        {localizationService.toLanguageString("custom.loading")}
      </span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const disabletitle = () => {
    let title = "";
    if (!EditSubject && approved === true) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    }
    if (!EditSubject) {
      title = localizationService.toLanguageString("custom.nopermissiontoedit");
    } else if (approved === true) {
      title = localizationService.toLanguageString(
        "custom.thesectionhasbeenapproved"
      );
    } else {
      title = localizationService.toLanguageString("custom.savechanges");
    }
    return title;
  };

  const disablefunction = () => {
    let boolean = false;
    if (!EditSubject || loading || approved) {
      boolean = true;
    }
    return boolean;
  };

  if (error && Noteserror && Attchementserror)
    return <>{localizationService.toLanguageString("custom.failedload")}</>;

  return (
    <TrialMap>
      {(!data || !notes || !attachements) && loadingPanel}

      <div className="row">
        <div className="col-12">
          <Card>
            <CardHeader className="DetailFormHeader">
              <div className="row">
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.subjectid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString(
                    "custom.subjectinitials"
                  )}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.study")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.protocolid")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.sitenumber")}
                </div>
                <div className="col DetailCardElementTitle">
                  {localizationService.toLanguageString("custom.createdby")}
                </div>
              </div>
              <div className="row">
                <div className="col text-responsive-overflow subject-id-style">
                  {props.location.state ? props.location.state.data.crf_id : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_initial
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_name
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.study_protocol
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.subject_center
                    : ""}
                </div>
                <div className="col text-responsive-overflow">
                  {props.location.state
                    ? props.location.state.data.createdBy
                    : ""}
                </div>
              </div>
              <hr className="RowRoleHr" />
              <div className="row">
                <div className="col pages-big-title">
                  <strong>
                    {localizationService.toLanguageString(
                      "custom.concomitantmedications"
                    )}
                  </strong>
                </div>
                <div className="col">
                  <div className="float-right">
                    <Button onClick={goBack} className="back-button-style">
                      {localizationService.toLanguageString("custom.back")}
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12">
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.concomitantmedications"
              )}
            >
              <MyContext.Provider value={{ FormRef: MyForm }}>
                <Form
                  initialValues={{
                    concomitant_medications: data ? AllData : [],
                  }}
                  ref={MyForm}
                  onSubmitClick={CreateConcomMedications}
                  key={JSON.stringify(AllData)}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement className="">
                      <fieldset className={"k-form-fieldset"}>
                        <Tooltip
                          openDelay={0}
                          anchorElement="target"
                          parentTitle={true}
                          position={"top"}
                        >
                          <Prompt
                            when={PromptFunction(formRenderProps)}
                            message={localizationService.toLanguageString(
                              "custom.promptmessage"
                            )}
                          />

                          <div className="row mt-4">
                            <div className="col">
                              <FieldArray
                                name="concomitant_medications"
                                component={FormGrid}
                                validator={arrayLengthValidator}
                              />
                            </div>
                          </div>
                        </Tooltip>
                      </fieldset>
                      <Tooltip
                        openDelay={0}
                        anchorElement="target"
                        parentTitle={true}
                        position={"left"}
                      >
                        <div className="float-right">
                          <div title={disabletitle()}>
                            <Button
                              primary={true}
                              type={"submit"}
                              className="mr-2em"
                              disabled={disablefunction()}
                            >
                              {loading
                                ? localizationService.toLanguageString(
                                    "custom.loading"
                                  )
                                : localizationService.toLanguageString(
                                    "custom.save"
                                  )}
                            </Button>
                          </div>
                        </div>
                      </Tooltip>
                    </FormElement>
                  )}
                />
              </MyContext.Provider>
            </TabStripTab>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.approvesection"
              )}
            >
              <ApproveStudySubject
                study_subject={props.location.state.data.id}
                section={props.location.state.section.id}
                data={props.location.state.data}
                parentCallBack={ChangeApproveSection}
              />
            </TabStripTab>
            <TabStripTab
              title={localizationService.toLanguageString(
                "custom.notesnattachements"
              )}
            >
              <TabStrip selected={Subselected} onSelect={handleSubSelect}>
                <TabStripTab
                  title={localizationService.toLanguageString("custom.notes")}
                >
                  <GridTelerik data={notes}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddNote()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>

                    <Column
                      field={"note"}
                      title={localizationService.toLanguageString(
                        "custom.note"
                      )}
                      cell={NoteNameTemplate}
                    />
                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.datecreated"
                      )}
                      cell={DateCreatedTemplate}
                    />
                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Noteactions}
                    />
                  </GridTelerik>
                </TabStripTab>

                <TabStripTab
                  title={localizationService.toLanguageString(
                    "custom.attachements"
                  )}
                >
                  <GridTelerik data={attachements}>
                    <GridToolbar>
                      <Button
                        primary={true}
                        onClick={() => {
                          {
                            EditSubject
                              ? toggleDaddFile()
                              : toggleDPermission();
                          }
                        }}
                      >
                        {localizationService.toLanguageString("custom.new")}
                      </Button>
                    </GridToolbar>
                    <Column
                      field={"title"}
                      title={localizationService.toLanguageString(
                        "custom.title"
                      )}
                    />

                    <Column
                      field={"date_created"}
                      title={localizationService.toLanguageString(
                        "custom.dateadded"
                      )}
                      cell={DateCreatedTemplate}
                    />

                    <Column
                      title={localizationService.toLanguageString(
                        "custom.actions"
                      )}
                      width="100px"
                      cell={Fileactions}
                    />
                  </GridTelerik>
                </TabStripTab>
              </TabStrip>
            </TabStripTab>
          </TabStrip>
        </div>
      </div>

      <NotificationGroup style={position.topCenter}>
        <Fade>
          {nosave && (
            <Notification
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nosave: false })}
            >
              <span>
                {ErrorNotification}{" "}
                {localizationService.toLanguageString("custom.errornosave")}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {nomodify && (
            <Notification
              type={{
                style: "warning",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, nomodify: false })}
            >
              <span>
                {localizationService.toLanguageString(
                  "custom.nomodificationchange"
                )}
              </span>
            </Notification>
          )}
        </Fade>
        <Fade>
          {success && (
            <Notification
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => setState({ ...state, success: false })}
            >
              <span>
                {localizationService.toLanguageString("custom.datasaved")}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {/* MODIFY REASON DIALOG */}
      {VModifyreason && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.modificationreason"
          )}
          onClose={toggleDModifyreason}
          width={"700px"}
          height={"400px"}
        >
          <div>
            <p style={{ margin: "25px", textAlign: "center" }}>
              {localizationService.toLanguageString(
                "custom.pleasespecifymodifyreason"
              )}
            </p>
            <Form
              initialValues={{
                modification_reason: "",
              }}
              onSubmit={CreateModification}
              render={(formRenderProps) => (
                <FormElement className="">
                  <fieldset className={"k-form-fieldset"}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          id={"modification_reason"}
                          name={"modification_reason"}
                          label={localizationService.toLanguageString(
                            "custom.reason"
                          )}
                          validator={requiredValidator}
                          component={RequiredFormTextArea}
                        />
                      </div>
                    </div>

                    <div className="row mb-2 mt-2">
                      <div className="col-12">
                        <div className="float-right">
                          <Button
                            primary={true}
                            type={"submit"}
                            className="mr-2em"
                          >
                            {localizationService.toLanguageString(
                              "custom.save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </div>
        </Dialog>
      )}
      {/* PERMISSION DIALOG */}
      {VPermission && (
        <Dialog
          title={localizationService.toLanguageString(
            "custom.permissiondenied"
          )}
          onClose={toggleDPermission}
        >
          <PermissionDenied onClose={toggleDPermission} />
        </Dialog>
      )}

      {VaddNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.addnote")}
          onClose={toggleDaddNote}
        >
          <AddNote
            onClose={toggleDaddNote}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VeditNote && (
        <Dialog
          width="700px"
          title={localizationService.toLanguageString("custom.editnote")}
          onClose={toggleDeditNote}
        >
          <AddNote onClose={toggleDeditNote} data={EditNote} />
        </Dialog>
      )}
      {Vdelete && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDdelete}
        >
          <DeleteObject onClose={toggleDdelete} Name={Name} api={DeleteAPI} />
        </Dialog>
      )}

      {VaddFile && (
        <Dialog
          width="600px"
          title={localizationService.toLanguageString("custom.upload")}
          onClose={toggleDaddFile}
        >
          <AddFile
            onClose={toggleDaddFile}
            section={props.location.state.section.id}
            subject={props.location.state.data.id}
          />
        </Dialog>
      )}
      {VTabStripChange && (
        <Dialog
          title={localizationService.toLanguageString("custom.confirm")}
          onClose={toggleDTabStripChange}
        >
          <TabChange
            selected={selectedTab}
            parentCallBack={TabCallBack}
            onClose={toggleDTabStripChange}
          />
        </Dialog>
      )}
    </TrialMap>
  );
}

export default ListConcomitantMedication;
